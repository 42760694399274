import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import favicon from '../../assets/favicon.ico';

const HeadSettings = ({ pageUrl }) => (
    <Helmet>
      <link rel="shortcut icon" href={favicon} />
      {pageUrl && <link href={pageUrl} rel="canonical" />}
      <script src="//i.kissmetrics.com/i.js" async />
      <script src="//doug1izaerwt3.cloudfront.net/68f9313d866df031326598d49a29feb2a560246d.1.js" async />
      <script async>
        {`WebFontConfig = {
            google: {
            families: ['Overpass Mono'],
          },
            typekit: {
            id: 'jpz3ejo',
            families: ['Soleil'],
          },
        };

        (function(d) {
          var wf = d.createElement('script'), s = d.scripts[0];
          wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
          wf.async = true;
          s.parentNode.insertBefore(wf, s);
        })(document);`}
      </script>
    </Helmet>
);

HeadSettings.propTypes = {
  pageUrl: PropTypes.string,
};

HeadSettings.defaultProps = {
  pageUrl: '',
};

export default HeadSettings;
